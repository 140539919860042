body {
  background: #f5f5f5;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}
.App {
  border-bottom: 2px solid rgb(229 229 229);
  border-top: 2px solid rgb(237, 65, 65);
  padding-bottom: 40px;
  background: #fff;
}
#convert {
  text-align: center;
}
#convert input[type=text] {
  border-radius: 10px;
  font-size: clamp(16px, 3vw, 32px);
  padding: 5px 20px;
  width: 60vw;
  min-height: 30px;
  background: rgb(0 0 0 / 10%);
  border: 0;
  outline: 0;
  color:#333333;
  max-width: 750px;
}
#conver input[type=text]:focus {
  color: #333;
}
#topbar {
  background: rgb(101 101 101);
  opacity: 0.3;

}
#logo img {
  height: 64px;
  padding: 5px 0;

}
#slogan {
  display: block;
  text-align: center;
  padding-top: 20px;
  vertical-align: top;
  color: #000;
  font-size: 24px;
  margin-bottom: 4vh;
}
input[type=button], #downloadBtn {
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 3px;
  display: inline-block;
  outline: 0;
  border: 0;
  background-color: #ed4141;
  color: #fff;
  opacity: 0.8;
  margin: 20px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #d1d1d1 inset !important;
}
/*Change text in autofill textbox*/
input:-webkit-autofill{
  -webkit-text-fill-color: #fff !important;
}
input[type=button]:hover , #downloadBtn:hover{
  opacity: 1.0;
}
#download {
  text-align: center;
}
#downloadLoading {
  visibility: hidden;
  text-align: center;
  margin-right: -120px;
}
#downloadBtn {
  visibility: hidden;
  text-align: center;
  text-decoration: none;

}

.loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 3px solid #ed4141;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}